import { Link } from 'react-router-dom';
import "./style.scss";

const PageNotFound = () => {
    return (
        <div className="main__container">
            <img src="/images/404.png" className="notfound" alt="Erreur 404" />
            <Link className="notfound__link" to="/">Go to Homepage</Link>  
        </div>
    );
}

export default PageNotFound;