import axios from 'axios';

// Récupération du token Instagram depuis les variables d'environnement
const IG_TOKEN = process.env.REACT_APP_IG_TOKEN;

const fetchInstagramPosts = async () => {
    if (!IG_TOKEN) {
        console.error("La variable d'environnement REACT_APP_IG_TOKEN est manquante !");
        return [];
    }

    try {
        // Obtention du access_token
        const tokenResponse = await axios.get(
            `https://graph.instagram.com/refresh_access_token`,
            {
                params: {
                    grant_type: "ig_refresh_token",
                    access_token: IG_TOKEN,
                },
            }
        );
        const accessToken = tokenResponse.data.access_token;

        // Obtention des posts
        const postsResponse = await axios.get(
            `https://graph.instagram.com/me/media`,
            {
                params: {
                    fields: "media_url,thumbnail_url,permalink",
                    access_token: accessToken,
                },
            }
        );

        return postsResponse.data.data.slice(0, 3);
    } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
        return [];
    }
};

export default fetchInstagramPosts;