import "./style.scss";
import React from "react";

/**
 * Composant Marquee
 * Ce composant affiche un texte défilant de manière continue.
 * Le texte (ou l'élément JSX) est répété trois fois pour créer l'effet de défilement.
 *
 * @param {string|React.Element} info - Le texte ou l'élément JSX à afficher dans le défilement.
 */
const Marquee = ({ info }) => {
    return (
        <div className="marquee">
            {/* Le contenu du défilement est affiché trois fois pour créer un effet de boucle infinie */}
            {/* Vérifie si l'info est un élément JSX valide, sinon l'affiche comme texte */}
            <div className="marquee__content">
                {React.isValidElement(info) ? info : <span>{info}</span>}
            </div>
            <div className="marquee__content">
                {React.isValidElement(info) ? info : <span>{info}</span>}
            </div>
            <div className="marquee__content">
                {React.isValidElement(info) ? info : <span>{info}</span>}
            </div>
            <div className="marquee__content">
                {React.isValidElement(info) ? info : <span>{info}</span>}
            </div>
        </div>
    );
};

export default Marquee;
