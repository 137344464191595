import React from "react";
import { useContext } from "react";
import { MagasinContext } from "../../services/MagasinContext";
import getImageUrl from "../../services/getImageUrl"; 
// import { Link } from "react-router-dom";
import "./style.scss";
import Banner from "../../components/Banner";
import CardMarque from "../../components/CardMarque";

/**
 * Composant `Marques` : Page affichant les différentes marques proposées par Optique Tavernier
 * 
 * - `marquesData` : Données statiques des marques avec leurs bannières et images associées.
 * - `infosMagasin` : État local pour stocker les informations du magasin récupérées depuis l'API.
 * - `Loader` : Affiché pendant le chargement des données pour indiquer à l'utilisateur que les informations sont en cours de récupération.
 * - `Banner` : Affiche un en-tête pour la section des marques.
 * - `CardMarque` : Composant utilisé pour afficher chaque marque avec une image de bannière et éventuellement un overlay.
 * 
 * Une fois les données chargées, le composant affiche :
 * - Un en-tête de section
 * - Une description des montures proposées
 * - Une liste de cartes pour chaque marque, avec des images associées
 */
const Marques = () => {

    // Accéder aux données de la page d'equipe depuis le contexte
    const { pageMarque } = useContext(MagasinContext);

    // Ne rien afficher si les données ne sont pas encore disponibles
    if (!pageMarque) return null; 

    return (
        <main>
            <section className="accueil__banner">
                <Banner info={<h1>Les marques que nous proposons・</h1>} />
            </section>
            <div className="main__container">
                <section>
                    <h2 className="titleUnderline">
                        Les montures de Tavernier
                    </h2>
                    <p>{pageMarque.intro}</p>
                </section>
                <section>
                    <div className="row justify-content-center g-5">
                        {pageMarque.type_marque.map((type_marque, index) => (
                        <div className="col-12" key={`${index}-type-${type_marque.nom_marque}`}>
                            <CardMarque
                                banner={getImageUrl(type_marque.image)}
                                type={type_marque.type}
                                overlay="active"
                            />
                            <div className="row my-4">
                                {type_marque.gallerie_marque.map((item, idx) => {
                                    const isLastItem = idx === type_marque.gallerie_marque.length - 1;
                                    const isOddCount = type_marque.gallerie_marque.length % 2 !== 0;
                                    
                                    return (
                                    <div
                                        className={`col-12 ${isLastItem && isOddCount ? 'col-md-12' : 'col-md-6'}`}
                                        key={`${idx}-galerie-${item.nom_marque}`}
                                    >
                                        <h2 className="titleUnderline mb-3">{item.nom_marque}</h2>
                                        <CardMarque banner={getImageUrl(item.image)} />
                                    </div>
                                    );
                                })}
                            </div>
                        </div>
                        ))}
                    </div>
                </section>
            </div>
        </main>
    );
};

export default Marques;