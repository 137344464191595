import { useContext } from "react";
import { MagasinContext } from "../../services/MagasinContext";
import getImageUrl from "../../services/getImageUrl"; 
import Banner from "../../components/Banner";
import "./style.scss";
import CardServiceInfo from "../../components/CardServiceInfo";

/**
 * Composant `Services` : Page présentant les services proposés par Optique Tavernier
 * 
 * Ce composant affiche une liste détaillée des services offerts par Optique Tavernier, 
 * y compris des informations sur la réparation, le bilan visuel, le montage, et d'autres services spécialisés.
 * 
 * - `servicesInfosData` : Données statiques représentant les différents services proposés. Chaque service comprend une image, un titre et une description.
 * 
 * La page est structurée en sections :
 * - Un en-tête avec le composant `Banner` pour présenter le titre de la page.
 * - Une section décrivant l'expertise en verres progressifs de l'entreprise.
 * - Une section listant les différents services avec leurs images et descriptions, affichés à l'aide du composant `CardServiceInfo`.
 */
const Services = () => {

    // Accéder aux données de la page d'equipe depuis le contexte
    const { pageService } = useContext(MagasinContext);

    // Ne rien afficher si les données ne sont pas encore disponibles
    if (!pageService) return null; 

    return (
        <main>
            <section className="accueil__banner">
                <Banner info={<h1>Optique Tavernier à votre service・</h1>} />
            </section>
            <div className="main__container">
                <section>
                    <h2 className="titleUnderline">{pageService.titre_service}</h2>
                    <p>{pageService.descriptif_service}</p>
                </section>
                <section>    
                    <div className="row g-3">
                        {pageService.service.map((service, index) => (
                        <div className="col-12" key={index}>
                            <h2 className="titleUnderline mb-3">
                                {service.titre}
                            </h2>
                            <CardServiceInfo
                                image={getImageUrl(service.image)}
                                title={service.titre}
                                content={service.contenu}
                                lien={service.lien}
                                btn={service.texte_bouton_lien}
                            />
                        </div>
                        ))}
                    </div>
                </section>
            </div>
        </main>
    );
}

export default Services;