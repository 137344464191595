import "./style.scss";

/**
 * Composant Loader
 * Ce composant affiche un indicateur de chargement (spinner) centré horizontalement sur la page.
 * Il est utilisé pour indiquer à l'utilisateur qu'une action est en cours (comme le chargement de données).
 */
const Loader = () => {
    return (
        <div className="container-fluid text-center my-4">
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
}

export default Loader