import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { MagasinContext } from "./MagasinContext";

/**
 * Composant Meta
 *
 * Ce composant utilise react-helmet-async pour mettre à jour dynamiquement les balises meta
 * du <head> du document en fonction de la page actuellement affichée. Il se base sur le
 * chemin de l'URL (pathname) pour déterminer quelles balises meta utiliser.
 *
 * Il accède au contexte MagasinContext pour récupérer les informations des balises meta
 * spécifiques à chaque page (comme title, description). Les données de balises
 * meta sont affichées dans les balises <title>, <meta name="description">
 * du <head>.
 */
const Meta = () => {
    const { pathname } = useLocation();
    const { baliseMeta } = useContext(MagasinContext);

    if (!baliseMeta) return null; // Ne rien afficher si les données ne sont pas encore disponibles

    // Détermine la clé pour accéder aux données meta spécifiques à la page en cours
    const pageKey = pathname === "/" ? "accueil" : pathname.substring(1); // Ex. '/qui-sommes-nous' -> 'qui-sommes-nous'

    // Accède aux données meta pour la clé déterminée ou utilise un objet vide si la clé n'existe pas
    const metaData = baliseMeta[pageKey] || {};

    return (
        // Rend le composant Helmet pour modifier les balises meta du head du document
        <Helmet>
            <title>{metaData.meta_title || "Optique Tavernier, opticien en centre-ville d’Epinal - 88000"}</title>
            <meta
                name="description"
                content={metaData.meta_description || "Opticien indépendant à Epinal, Optique Tavernier vous propose des lunettes de vue, des lunettes de soleil, des lentilles de contact et des services de qualité."}
            />
        </Helmet>
    );
};

export default Meta;
