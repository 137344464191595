import NavBar from "../NavBar";
import "./style.scss";

/**
 * Composant Header
 * Ce composant représente l'en-tête du site web, comprenant la barre de navigation et la bannière.
 * Il affiche dynamiquement un message dans la bannière en fonction de la page actuelle.
 */
const Header = () => {
    return (
        <header>
            <NavBar />
        </header>
    );
};

export default Header;
