/**
 * Utilitaire pour construire l'URL complète des images
 * @param {Objet} imageData - Objet contenant les données de l'image récupérés depuis Strapi.
 */
const getImageUrl = (imageData) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL; // Utiliser la variable d'environnement
  if (imageData?.data?.attributes?.url) {
    return `${baseUrl}${imageData.data.attributes.url}`;
  }
};

export default getImageUrl;