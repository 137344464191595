import { useContext } from "react";
import { MagasinContext } from "../../services/MagasinContext";
import getImageUrl from "../../services/getImageUrl"; 
import Banner from "../../components/Banner";
import Timeline from "../../components/TimeLine";
import "./style.scss";


/**
 * Composant `QuiSommesNous` : Page présentant l'historique et l'équipe de l'Optique Tavernier
 * 
 * Ce composant affiche l'historique de l'entreprise sous forme de chronologie, ainsi que des informations sur l'équipe actuelle.
 * 
 * - `timelineData` : Données statiques représentant les événements clés de l'histoire de l'entreprise.
 * - `equipe` : URL de l'image de l'équipe pour l'afficher dans la section correspondante.
 * - `equipeData` : Données statiques concernant les membres de l'équipe, y compris leur nom, leur rôle, et leur image.
 * 
 * La page est structurée en sections :
 * - Un en-tête avec le composant `Banner`
 * - Une chronologie des événements clés
 * - Une section pour afficher l'image de l'équipe
 * - Une section pour afficher les membres de l'équipe avec leurs informations et images
 */
const Equipe = () => {

    // Accéder aux données de la page d'equipe depuis le contexte
    const { pageEquipe } = useContext(MagasinContext);

    // Ne rien afficher si les données ne sont pas encore disponibles
    if (!pageEquipe) return null; 

    return (
        <main>
            <section className="accueil__banner">
                <Banner info={<h1>Qui sommes-nous ?・</h1>} />
            </section>
            <div className="main__container">
                <section>
                    <h2 className="titleUnderline">Les dates clés</h2>
                    <ul className="timeline ms-5">
                        {pageEquipe.timeline.map((item, index) => (
                        <Timeline 
                            key={index} 
                            date={item.date} 
                            content={item.contenu} />
                        ))}
                    </ul>
                </section>
                <section>
                    <h2 className="titleUnderline">L'équipe Tavernier</h2>
                    <div className="d-flex justify-content-center">
                        <div className="col-12 col-md-6">
                            <div className="card text-center text-white p-0">
                                <img src={getImageUrl(pageEquipe.photo_equipe)} className="card-img" alt="L'équipe" />
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="row justify-content-center g-4">
                        {pageEquipe.membre.map((membre, index) => (
                        <div key={index} className="col-12 col-lg-6 d-flex flex-column justify-content-between">
                            <div>
                                <h2 className="titleUnderline">{membre.nom}</h2>
                                <p>{membre.contenu}</p>
                            </div>
                            <div className="card">
                                <img src={getImageUrl(membre.image)} className="card-img" alt={membre.nom} />
                            </div>
                        </div>
                        ))}
                    </div>
                </section>
            </div>
        </main>
    );
};

export default Equipe;
