/**
 * Injecte les variables CSS dynamiques dans le document.
 * @param {Object} styleData - Objet contenant les styles récupérés depuis Strapi.
 */
const injectCSSVariables = (styleData) => {
    if (styleData) {
        const root = document.documentElement;
        root.style.setProperty('--primary', styleData.color_fond_page || '#fefbf2');
        root.style.setProperty('--secondary', styleData.color_principale_texte || '#563127');
        root.style.setProperty('--tertiary', styleData.color_titre_bouton || '#e55938');
    }
};

export default injectCSSVariables;