import "./style.scss";
import React from 'react';

/**
 * Composant CardMarque
 * Ce composant affiche une carte de marque avec une image de fond et un titre. 
 * Un overlay avec le titre peut être affiché si la prop "overlay" est définie à "active".
 * 
 * @param {string} banner - URL de l'image de fond de la carte.
 * @param {string} type - Type de clientèle sur la carte (par exemple, Femme, Homme, Enfant).
 * @param {string} overlay - Si défini à "active", un overlay avec le titre est affiché sur l'image.
 */
const CardMarque = ({banner, type, overlay}) => {
  return (
    <div className="CardMarque card text-center text-white p-0">
      <img src={banner} className="CardServiceInfo__image  card-img" alt="Service" />
      {overlay === "active" && (
        <div className="CardMarque__overlay d-flex flex-column justify-content-center align-items-center">
          <h2 className="CardMarque__title">{type}</h2>
        </div>
      )}
    </div>
  );
};

export default CardMarque;