import Banner from "../../components/Banner";
import "./style.scss";
import { MagasinContext } from "../../services/MagasinContext";
import Loader from "../../components/Loader";
import { useContext } from "react";

/**
 * Composant `Mentions` : Page affichant les mentions légales et la politique de confidentialité
 */
const Mentions = () => {

    const { infosMagasin } = useContext(MagasinContext);
    // Affichage d'un Loader pendant le chargement des informations si délai - s'assure que les données soient dispos avant affichage
    if (!infosMagasin) {
        return <Loader />;
    }

    return (
        <main>
            <section className="accueil__banner">
                <Banner info={<h1>Mentions légales・</h1>} />
            </section>
            <div className="mentions-content">
                <h1 className="title">Mentions légales </h1>
                <h3>1 – Editeur</h3>
                <p>
                    Site : optiquetavernier.fr<br />
                    Propriétaire du site : {infosMagasin.proprietaire}<br />
                    Éditeur : Optique Tavernier<br />
                    Siège social : {infosMagasin.adresse_postale} - {infosMagasin.code_postal_ville}<br />
                    Numéro SIREN : {infosMagasin.siren}<br />
                    SIRET : {infosMagasin.siret}<br />
                    Téléphone : {infosMagasin.telephone}<br />
                    Directeur de la publication : {infosMagasin.proprietaire}
                </p>

                <h3>2 - Conception et création du site </h3>
                <p>
                    Le site a été réalisé par la société Optic Performance :<br />
                    Optic Performance, SAS au capital de 10 000 €<br />
                    Siège social : Prisme, Place Albert Einstein, 56000 Vannes, France<br />
                    Numéro SIREN : 813 258 191<br />
                    SIRET : 81325819100010<br />
                    Téléphone : 02 97 68 20 60<br />
                    Site : <a href="https://www.optic-performance.com/">https://www.optic-performance.com/</a>
                </p>

                <h3>3 – Hébergeur</h3>
                <p>
                    Hébergeur du site : Heroku EU
                    Site : <a href="https://www.heroku.com/">https://www.heroku.com/</a>
                </p>

                <h3>4 – Droits de reproduction</h3>
                <p>
                    Ce Site est la propriété exclusive de la société Optique Tavernier, seule habilitée à utiliser et exploiter les droits de propriété intellectuelle attachés à ce site.<br />
                    Toute reproduction, représentation, utilisation ou adaptation de tout ou partie* du site sont strictement interdites.<br />
                    *marques, photographies, textes, commentaires, illustrations, images animées ou non, séquences vidéo, sons, applications informatiques et tous les éléments sur le site.
                </p>

                <h3>5 – Loi applicable</h3>
                <p>Les présentes conditions d’utilisation du site sont régies par la loi française, sous réserve d’une attribution de compétence spécifique découlant d’un texte de loi ou réglementaire particulier.</p>

                <h3>6 – Gestion du site</h3>
                <p>
                    Pour la bonne gestion du site, l’éditeur pourra à tout moment :<br />
                    – suspendre, interrompre ou limiter l’accès à tout ou partie du site, réserver l’accès au site, ou à certaines parties du site, à une catégorie déterminée d’internaute ;<br />
                    – supprimer toute information pouvant en perturber le fonctionnement ou entrant en contravention avec les lois nationales ou internationales, ou avec les règles de la Nétiquette ;<br />
                    – suspendre le site afin de procéder à des mises à jour.
                </p>

                <h3>7 – Responsabilités</h3>
                <p>
                    La responsabilité de l’éditeur ne peut être engagée en cas de défaillance, panne, difficulté ou interruption de fonctionnement, empêchant l’accès au site ou à une de ses fonctionnalités.<br />
                    L’éditeur n’est pas responsable des dommages causés à vous-même, à des tiers et/ou à votre équipement du fait de votre connexion ou de votre utilisation du site et vous renoncez à toute action contre lui de ce fait.
                </p>

                <h3>8 – Liens hypertextes</h3>
                <p>Toute information accessible via un lien vers d’autres sites n’est pas sous le contrôle de l’éditeur qui décline toute responsabilité quant à leur contenu.</p>

                <h2 className="title">Politique de confidentialité</h2>
    
                <h3>1 - Introduction</h3>
                <p>Dans le cadre de son activité, le magasin Optique Tavernier dont le siège social est situé au 16 Place des Vosges 88000 Epinal est amenée à collecter et à traiter des informations dont certaines sont qualifiées de "données personnelles". Optique Tavernier attache une grande importance au respect de la vie privée, et n’utilise que des données de manière responsable et confidentielle et dans une finalité précise.</p>

                <h3>2 - Données personnelles</h3>
                <p>
                    Sur le site web optiquetavernier.fr il y a 2 types de données susceptibles d’être recueillies :<br />
                    • Les données transmises directement par l’utilisateur<br />
                    Ces données sont celles que vous nous transmettez directement, via les formulaires de contact, via l’inscription à la newsletter ou bien par contact direct par email. Sont obligatoires dans les formulaires de contact les champs personnels « Nom » et « Email ».<br />
                    • Les données collectées automatiquement<br />
                    Lors de vos visites, une fois votre consentement donné, nous pouvons recueillir des informations de type « web analytics » relatives à votre navigation, la durée de votre consultation, votre adresse IP, votre type et version de navigateur. La technologie utilisée est le cookie.
                    Les données seront sauvegardées durant une durée maximale de 3 ans.
                </p>

                <h3>3 - Cookies</h3>
                <p>
                    Les cookies ne sont collectés qu’après consentement de l’utilisateur. Ce consentement est recueilli via Axeptio.<br />
                    Voici la liste des cookies utilisés et leur objectif :<br />
                    • Cookies Google Analytics : Permet d’analyser les statistiques de consultation et performance de notre site (Web analytics) ;<br />
                    • Axeptio : Permet de garder en mémoire vos choix concernant les cookies afin de faciliter votre prochaine visite.
                </p>

                <h3>4 - Utilisation des données</h3>
                <p>
                    Les données que vous nous transmettez directement sont utilisées dans le but de vous re-contacter et/ou dans le cadre de la demande que vous nous faites.<br />
                    Les données « web analytics » sont collectées de forme anonyme (en enregistrant des adresses IP anonymes) par Google Analytics, et nous permettent de mesurer l'audience de notre site web, les consultations et les éventuelles erreurs afin d’améliorer constamment l’expérience des utilisateurs. Ces données sont utilisées en interne par l’équipe, et ne seront jamais cédées à un tiers ni utilisées à d’autres fins que celles détaillées ci-dessus.
                </p>

                <h3>5 - Consentement</h3>
                <p>
                    Les données personnelles ne sont collectées qu’après consentement de l’utilisateur. Ce consentement est valablement recueilli (boutons et cases à cocher*), libre, clair et sans équivoque :<br />
                    *Cases à cocher pour les données transmises directement par l’utilisateur / Bouton de consentement pour les cookies
                </p>

                <h3>6 - Vos droits concernant les données personnelles</h3>
                <p>Vous avez le droit de consultation, de modification ou d’effacement sur l’ensemble de vos données personnelles. Vous pouvez également retirer votre consentement au traitement de vos données.  Pour exercer ces droits ou pour toute question sur le traitement de vos données, vous pouvez nous contacter par courriel, courrier ou téléphone. </p>
    
                <h3>7 - Contact délégué à la protection des données</h3>
                <p>
                    Sébastien Favre<br />
                    Optic Performance, Place Albert Einstein, 56000 Vannes<br />
                    02 97 68 14 66
                </p>
            </div>
        </main>
    );
}

export default Mentions;