import { useContext } from "react";
import BtnArrow from "../BtnArrow";
import "./style.scss";
import { useLocation, Link } from 'react-router-dom';
import { MagasinContext } from "../../services/MagasinContext";
import getImageUrl from "../../services/getImageUrl";

/**
 * Composant NavBar
 * Ce composant représente la barre de navigation fixe en haut de la page.
 * Elle contient des liens vers les différentes sections du site, ainsi qu'un bouton de prise de rendez-vous.
 */
const NavBar = () => {

  // Accéder aux données du style depuis le contexte
  const { dataStyle } = useContext(MagasinContext);

  // Rend les Url des images
  const logo_entete = getImageUrl(dataStyle?.logo_entete);

  // Utilisation du hook `useLocation` pour obtenir le chemin actuel de la page
  const location = useLocation();
  const { pathname } = location;
  // Fonction qui retourne une classe CSS `active` si le chemin correspond à l'URL actuelle
  const getActiveClass = (path) => (pathname === path ? 'active' : '');

  return (
    <nav className="navbar navbar-expand-lg bg-body p-0 fixed-top nav-bg-color">
      <div className="container">

        {/* Lien vers la page d'accueil avec le logo du site */}
        <Link className="navbar-brand" to="/">
          <img src={logo_entete}  alt="Logo" width={260} height={80} className="d-inline-block align-text-top" />
        </Link>

        {/* Bouton pour afficher/cacher le menu sur les petits écrans */}
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>

        {/* Menu de navigation, se replie automatiquement sur petits écrans */}
        <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item nav-effect">
              <Link className={`nav-link ${getActiveClass('/')}`} to="/">Accueil</Link>
            </li>
            <li className="nav-item nav-effect">
              <Link className={`nav-link ${getActiveClass('/equipe')}`} to="/equipe">L'Equipe</Link>
            </li>
            <li className="nav-item nav-effect">
              <Link className={`nav-link ${getActiveClass('/services')}`} to="/services">Services</Link>
            </li>
            <li className="nav-item nav-effect">
              <Link className={`nav-link ${getActiveClass('/marques')}`} to="/marques">Marques</Link>
            </li>
            <li className="nav-item nav-effect">
              <Link className={`nav-link ${getActiveClass('/contact')}`} to="/contact">Contact</Link>
            </li>
          </ul>
          <a href="https://www.doctolib.fr/magasin-d-optique/epinal/optique-tavernier-opticien-a-epinal" target="_blank" rel="noopener noreferrer" className="link style-7 mb-3 mb-lg-0 ms-lg-2">
            <BtnArrow info="Rendez-vous" />
          </a>
        </div>
        
      </div>
    </nav>
  );
};

export default NavBar;