import "./style.scss";
import React, { useState } from 'react';

/**
 * Composant CardServiceInfo
 * Ce composant affiche une carte de service avec une image de fond, un titre, et une modale qui affiche les détails.
 * 
 * @param {string} image - URL de l'image de fond de la carte.
 * @param {string} title - Titre du service affiché sur la carte.
 * @param {string} content - Contenu structuré du service.
 * @param {string} lien - URL du lien qui sera utilisé pour le bouton.
 * @param {string} btn - Texte du bouton.
 */
const CardServiceInfo = ({ image, title, content, lien, btn }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="CardServiceInfo card text-center text-white p-0">
      <img src={image} className="CardServiceInfo__image card-img" alt="Service" />
      <div className="CardServiceInfo__overlay d-flex flex-column justify-content-center align-items-center" onMouseOver={openModal}>
        <h3 className="CardServiceInfo__title">{title}</h3>
        <button
          type="button"
          className="btn btn-primary"
        >
          <span>En Savoir Plus</span>
        </button>
        {isModalOpen && (
          <div className="CardServiceInfo__modal" onMouseLeave={closeModal}>
            <div className="CardServiceInfo__modal--content">
              <p className="CardServiceInfo__modal--content-title">{title}</p>
              <div>
                <p>{content}</p>
                {/* Afficher le bouton seulement si lien n'est pas null */}
                {lien && (
                  <a
                    href={lien}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="CardServiceInfo__modal--content-link"
                  >
                    {btn}
                  </a>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardServiceInfo;