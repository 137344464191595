import "./style.scss";

/**
 * Composant Timeline
 * Ce composant représente un élément d'une timeline (chronologie).
 * Il prend deux props : `date` et `content`, et génère un élément de la timeline
 * affichant une date en gras et une description associée.
 * 
 * Mappez le dans une balise <ul className="timeline ms-5"></ul>
 * 
 * avec en style:
 * .timeline {
 * border-left: 1px solid $tertiary;
 * position: relative;
 * list-style: none;
 * }
 *
 * @param {string} date - La date associée à l'événement ou l'étape de la timeline.
 * @param {string} content - Le contenu ou la description de l'événement associé à la date.
 *
 */
const Timeline = ({ date, content }) => {
    return (
      <li className="timeline-item mb-4">
        <p className="text-muted mb-0 fw-bold date">{date}</p>
        <p className="text-muted">{content}</p>
      </li>
    );
  };
  
  export default Timeline;