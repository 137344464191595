import React from 'react';
import ReactDOM from 'react-dom/client';
import "./styles/base/main.scss";
import Router from './router';
import { HelmetProvider } from 'react-helmet-async';
import { MagasinProvider } from './services/MagasinContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <MagasinProvider>
        <Router />
      </MagasinProvider>
    </HelmetProvider>
  </React.StrictMode>
);