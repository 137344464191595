// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*****Media Queries*****/
/***** Color list *****/
.footer__container {
  margin: 25px;
  color: var(--secondary);
}
.footer__container--logo {
  max-width: 300px;
}
.footer__container--text {
  border-top: solid 2px var(--secondary);
  padding: 30px;
}
.footer__container--text ul li strong {
  font-family: "Filson Pro Bold", sans-serif;
  font-size: 21px;
}
.footer__container--text ul li:first-child {
  margin-bottom: 15px;
}

.small__text {
  margin-left: 32px;
}
.small__text--link:hover {
  color: var(--tertiary);
}`, "",{"version":3,"sources":["webpack://./src/styles/utils/mixins.scss","webpack://./src/styles/utils/variables.scss","webpack://./src/components/Footer/style.scss"],"names":[],"mappings":"AAAA,wBAAA;ACAA,uBAAA;ACGA;EACE,YAAA;EACA,uBDFU;ACEZ;AACE;EACE,gBAAA;AACJ;AACE;EACE,sCAAA;EACA,aAAA;AACJ;AACM;EACE,0CAAA;EACA,eAAA;AACR;AACM;EACE,mBAAA;AACR;;AAKA;EACE,iBAAA;AAFF;AAII;EACE,sBDxBK;ACsBX","sourcesContent":["/*****Media Queries*****/\n\n@mixin Small {\n    @media (min-width: 576px) {\n        @content;\n    }\n}\n\n@mixin Medium {\n    @media (min-width: 768px) {\n        @content;\n    }\n}\n\n@mixin Large {\n    @media (min-width: 992px) {\n        @content;\n    }\n}\n\n@mixin Extra-large {\n    @media (min-width: 1200px) {\n        @content;\n    }\n}\n\n@mixin Extra-extra-large {\n    @media (min-width: 1400px) {\n        @content;\n    }\n}","/***** Color list *****/\n\n$primary: var(--primary);\n$secondary: var(--secondary);\n$tertiary: var(--tertiary);","@import \"../../styles/utils/mixins.scss\";\n@import \"../../styles/utils/variables.scss\";\n\n.footer__container {\n  margin: 25px;\n  color: $secondary;\n  &--logo {\n    max-width: 300px;\n  }\n  &--text {\n    border-top : solid 2px $secondary;\n    padding : 30px;\n    ul li {\n      strong {\n        font-family: \"Filson Pro Bold\", sans-serif;\n        font-size: 21px;\n      }\n      &:first-child {\n        margin-bottom: 15px;\n      }\n    }\n  } \n}\n\n.small__text {\n  margin-left: 32px;\n  &--link {\n    &:hover {\n      color : $tertiary;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
