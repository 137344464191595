// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/***** Color list *****/
/*****Media Queries*****/
.notfound {
  margin-top: 80px;
  height: 50vh;
  width: auto;
  object-fit: contain; /* Assure que l'image garde son ratio */
}
.notfound__link {
  margin-top: 20px;
  text-align: center;
  align-self: center;
  color: var(--secondary);
  font-size: 22px;
}
.notfound__link:hover {
  color: var(--tertiary); /* Change la couleur au survol */
}`, "",{"version":3,"sources":["webpack://./src/styles/utils/variables.scss","webpack://./src/styles/utils/mixins.scss","webpack://./src/pages/PageNotFound/style.scss"],"names":[],"mappings":"AAAA,uBAAA;ACAA,wBAAA;ACGA;EACI,gBAAA;EACA,YAAA;EACA,WAAA;EACA,mBAAA,EAAA,uCAAA;AAAJ;AACI;EACI,gBAAA;EACA,kBAAA;EACA,kBAAA;EACA,uBAAA;EACA,eAAA;AACR;AAAQ;EACI,sBAAA,EAAA,gCAAA;AAEZ","sourcesContent":["/***** Color list *****/\n\n$primary: var(--primary);\n$secondary: var(--secondary);\n$tertiary: var(--tertiary);","/*****Media Queries*****/\n\n@mixin Small {\n    @media (min-width: 576px) {\n        @content;\n    }\n}\n\n@mixin Medium {\n    @media (min-width: 768px) {\n        @content;\n    }\n}\n\n@mixin Large {\n    @media (min-width: 992px) {\n        @content;\n    }\n}\n\n@mixin Extra-large {\n    @media (min-width: 1200px) {\n        @content;\n    }\n}\n\n@mixin Extra-extra-large {\n    @media (min-width: 1400px) {\n        @content;\n    }\n}","@import \"../../styles/utils/variables.scss\";\n@import \"../../styles/utils/mixins.scss\";\n\n.notfound {\n    margin-top: 80px;\n    height: 50vh;\n    width: auto;\n    object-fit: contain; /* Assure que l'image garde son ratio */\n    &__link {\n        margin-top: 20px;\n        text-align: center;\n        align-self: center;\n        color: var(--secondary);\n        font-size: 22px;\n        &:hover {\n            color: var(--tertiary); /* Change la couleur au survol */\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
