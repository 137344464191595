import { useContext } from "react";
import Marquee from "../Marquee";
import "./style.scss";
import { MagasinContext } from "../../services/MagasinContext";
import getImageUrl from "../../services/getImageUrl";

/**
 * Composant Banner
 * Ce composant représente la bannière d'accueil du site web.
 * Il affiche le logo du magasin, des informations sur l'opticien, et un texte défilant en bas de la bannière.
 *
 * @param {string} info - Le texte à afficher dans la bande défilante (Marquee)
 */
const Banner = ({ info }) => {

    // Accéder aux données du style depuis le contexte
    const { dataStyle } = useContext(MagasinContext);

    // Rend les Url des images
    const logo_banniere = getImageUrl(dataStyle?.logo_banniere);

    return (
        // La section banner occupe toute la hauteur de la vue (vh-100)
        <section className="banner vh-100">
            <div className="banner__wrapper">
                <img
                    className="banner__wrapper--logo"
                    src={logo_banniere}
                    alt="Logo Tavernier"
                />
                <div className="banner__wrapper--text">
                    <p>Opticien lunetier</p>
                    <p>Depuis 1966</p>
                </div>
            </div>
            {/* Marquee affichant le texte défilant avec le contenu passé en prop */}
            <div className="banner__marquee">
                <Marquee info={info} />
            </div>
        </section>
    );
};

export default Banner;
