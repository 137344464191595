// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/***** Color list *****/
/*****Media Queries*****/
.insta__card {
  max-width: 400px;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/styles/utils/variables.scss","webpack://./src/styles/utils/mixins.scss","webpack://./src/pages/Accueil/style.scss"],"names":[],"mappings":"AAAA,uBAAA;ACAA,wBAAA;ACGA;EACE,gBAAA;EACA,cAAA;AAAF","sourcesContent":["/***** Color list *****/\n\n$primary: var(--primary);\n$secondary: var(--secondary);\n$tertiary: var(--tertiary);","/*****Media Queries*****/\n\n@mixin Small {\n    @media (min-width: 576px) {\n        @content;\n    }\n}\n\n@mixin Medium {\n    @media (min-width: 768px) {\n        @content;\n    }\n}\n\n@mixin Large {\n    @media (min-width: 992px) {\n        @content;\n    }\n}\n\n@mixin Extra-large {\n    @media (min-width: 1200px) {\n        @content;\n    }\n}\n\n@mixin Extra-extra-large {\n    @media (min-width: 1400px) {\n        @content;\n    }\n}","@import \"../../styles/utils/variables.scss\";\n@import \"../../styles/utils/mixins.scss\";\n\n.insta__card {\n  max-width: 400px;\n  margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
