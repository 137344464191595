import React, { useContext, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./style.scss";
import Banner from "../../components/Banner";
import Loader from "../../components/Loader";
import { MagasinContext } from "../../services/MagasinContext";


/**
 * Composant `Contact` : Page affichant le formulaire de contact et les informations d'adresse pour Optique Tavernier.
 * 
 * Ce composant gère l'affichage du formulaire de contact et d'une carte Google Maps.
 * Il se compose principalement de deux sections :
 * 
 * 1. `Banner` : Affiche une bannière en haut de la page avec un titre.
 * 2. Formulaire de contact : Permet aux utilisateurs de saisir leurs informations de contact et d'envoyer un message.
 * 3. Carte Google Maps : Affiche l'emplacement du magasin Optique Tavernier.
 * 
 * Le formulaire comprend les champs suivants :
 * - `Nom et Prénom` : Champ texte pour le nom complet de l'utilisateur.
 * - `Email` : Champ pour l'adresse e-mail de l'utilisateur.
 * - `Téléphone` : Champ pour le numéro de téléphone de l'utilisateur.
 * - `Sujet` : Champ pour l'objet du message.
 * - `Message` : Zone de texte pour le contenu du message.
 * 
 * Le formulaire utilise Bootstrap pour le style et comprend un bouton d'envoi.
 * La carte Google Maps est intégrée dans un `iframe` pour montrer l'emplacement du magasin.
 */
const Contact = () => {
    // Utiliser la variable d'environnement
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const { infosMagasin } = useContext(MagasinContext);

    const [isLoading, setIsLoading] = useState(false); 

    const [formData, setFormData] = useState({
        nomPrenom: "",
        email: "",
        telephone: "",
        sujet: "",
        message: ""
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {

        e.preventDefault()
        setIsLoading(true)

        try {
            const payload = {
                ...formData,
                destinataire: infosMagasin.mail,
                proprietaire: infosMagasin.proprietaire
            };

            const response = await fetch(`${baseUrl}/api/contact/send`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(payload),
            });
      
            const data = await response.json();

            if (response.ok) {
                setFormData({
                    nomPrenom: "",
                    email: "",
                    telephone: "",
                    sujet: "",
                    message: ""
                });
                toast.success(data.message, {
                    autoClose: 3000,
                    pauseOnHover: false,
                    position: "bottom-center",
                    onClose: () => setIsLoading(false)
                });
            } else {
                toast.error(data.error, {
                    autoClose: 3000,
                    pauseOnHover: false,
                    position: "bottom-center",
                    onClose: () => setIsLoading(false) 
                });
            }

        } catch (error) {
            toast.error("Erreur lors de l'envoi du formulaire", {
                autoClose: 3000,
                pauseOnHover: false,
                position: "bottom-center",
                onClose: () => setIsLoading(false) 
            });
        } 
    };

    // Affichage d'un Loader pendant le chargement des informations si délai - s'assure que les données soient dispos avant affichage
    if (!infosMagasin) {
        return <Loader />;
      }

    return (
        <main>
            <section className="accueil__banner">
                <Banner info={<h1>Contact Optique Tavernier・</h1>} />
            </section>
            <div className="main__container">
                <div className="row">
                    <section className="col-12 col-lg-7">
                        <h2 className="titleUnderline">
                            Formulaire de contact
                        </h2>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="nomPrenom" className="form-label">Nom et Prénom</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="nomPrenom"
                                    name="nomPrenom"
                                    required
                                    placeholder="John Doe"
                                    value={formData.nomPrenom}
                                    onChange={handleChange}
  
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email</label>
                                <input
                                    className="form-control"
                                    type="email"
                                    id="email"
                                    name="email"
                                    required
                                    placeholder="example@example.com"
                                    value={formData.email}
                                    onChange={handleChange}
                    
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="telephone" className="form-label">Téléphone</label>
                                <input
                                    className="form-control"
                                    type="tel"
                                    id="telephone"
                                    name="telephone"
                                    required
                                    placeholder="+33 6 12 34 56 78"
                                    value={formData.telephone}
                                    onChange={handleChange}
                               
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="sujet" className="form-label">Sujet</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="sujet"
                                    name="sujet"
                                    required
                                    placeholder="Objet de votre message"
                                    value={formData.sujet}
                                    onChange={handleChange}
                              
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="message" className="form-label">Message</label>
                                <textarea
                                    className="form-control"
                                    rows="4"
                                    id="message"
                                    name="message"
                                    required
                                    placeholder="Votre message ici..."
                                    value={formData.message}
                                    onChange={handleChange}
                              
                                />
                            </div>
                            <button type="submit" className="btn btn-primary mb-5">{isLoading ? "Envoi en cours..." : "Envoyer"}</button>
                        </form>
                        <ToastContainer />
                    </section>
                    <section className="col-12 col-lg-5">
                        <h2 className="titleUnderline">
                            Adresse
                        </h2>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5321.282268226668!2d6.445664024475504!3d48.17499735639618!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4793a0889c659ed1%3A0x271b3ce7f4a8d9b5!2sOptique%20Tavernier!5e0!3m2!1sfr!2sfr!4v1686831753961!5m2!1sfr!2sfr"
                            width="100%"
                            height="600"
                            style={{ border: 0 }}
                            allowFullScreen
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            title="Carte Google Maps montrant l'emplacement d'Optique Tavernier"
                        ></iframe>
                    </section>
                </div>
            </div>
        </main>
    );
};

export default Contact;
