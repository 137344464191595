// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/***** Color list *****/
/*****Media Queries*****/
.googlecard {
  border-radius: 8px;
  overflow: hidden;
}

.googlecard-body {
  padding: 1.25rem;
}

.googlecard-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid #ddd;
}

.googlecard-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--tertiary);
}

.googlecard-stars {
  font-size: 1.25rem;
}

.googlecard-stars .fa-star {
  margin-right: 0.1rem;
}

.googlecard-date {
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

.googlecard-content {
  font-size: 1rem;
  color: var(--secondary);
}`, "",{"version":3,"sources":["webpack://./src/styles/utils/variables.scss","webpack://./src/styles/utils/mixins.scss","webpack://./src/components/CardGoogle/style.scss"],"names":[],"mappings":"AAAA,uBAAA;ACAA,wBAAA;ACGA;EACE,kBAAA;EACA,gBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,YAAA;EACA,aAAA;EACA,iBAAA;EACA,kBAAA;EACA,sBAAA;AAAF;;AAGA;EACE,kBAAA;EACA,iBAAA;EACA,sBFnBS;AEmBX;;AAGA;EACE,kBAAA;AAAF;;AAGA;EACE,oBAAA;AAAF;;AAGA;EACE,mBAAA;EACA,mBAAA;AAAF;;AAGA;EACE,eAAA;EACA,uBFtCU;AEsCZ","sourcesContent":["/***** Color list *****/\n\n$primary: var(--primary);\n$secondary: var(--secondary);\n$tertiary: var(--tertiary);","/*****Media Queries*****/\n\n@mixin Small {\n    @media (min-width: 576px) {\n        @content;\n    }\n}\n\n@mixin Medium {\n    @media (min-width: 768px) {\n        @content;\n    }\n}\n\n@mixin Large {\n    @media (min-width: 992px) {\n        @content;\n    }\n}\n\n@mixin Extra-large {\n    @media (min-width: 1200px) {\n        @content;\n    }\n}\n\n@mixin Extra-extra-large {\n    @media (min-width: 1400px) {\n        @content;\n    }\n}","@import \"../../styles/utils/variables.scss\";\n@import \"../../styles/utils/mixins.scss\";\n\n.googlecard {\n  border-radius: 8px;\n  overflow: hidden;\n}\n\n.googlecard-body {\n  padding: 1.25rem;\n}\n\n.googlecard-image {\n  width: 150px;\n  height: 150px;\n  object-fit: cover;\n  border-radius: 50%;\n  border: 3px solid #ddd;\n}\n\n.googlecard-title {\n  font-size: 1.25rem;\n  font-weight: bold;\n  color: $tertiary;\n}\n\n.googlecard-stars {\n  font-size: 1.25rem; // Ajustez la taille des étoiles si nécessaire\n}\n\n.googlecard-stars .fa-star {\n  margin-right: 0.1rem; // Espacement entre les étoiles\n}\n\n.googlecard-date {\n  font-size: 0.875rem;\n  margin-bottom: 1rem;\n}\n\n.googlecard-content {\n  font-size: 1rem;\n  color: $secondary;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
