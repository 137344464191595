import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import injectCSSVariables from "./injectCSSVariables";
import Loader from "../components/Loader";

// Création du contexte
export const MagasinContext = createContext();

// Fournisseur de contexte qui enveloppe l'application
export const MagasinProvider = ({ children }) => {
    // Utiliser la variable d'environnement
    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    const [infosMagasin, setInfosMagasin] = useState(null);
    const [baliseMeta, setBaliseMeta] = useState(null);
    const [dataStyle, setDataStyle] = useState(null);
    const [pageAccueil, setPageAccueil] = useState(null);
    const [pageEquipe, setPageEquipe] = useState(null);
    const [pageService, setPageService] = useState(null);
    const [pageMarque, setPageMarque] = useState(null);

    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Indiquer le début du chargement
            setErrors([]); // Réinitialiser les erreurs

            try {
                // Récupération des balises Meta
                try {
                    const metaResponse = await axios.get(`${baseUrl}/api/balise-meta?populate=*`);
                    setBaliseMeta(metaResponse.data.data.attributes);
                } catch (err) {
                    setErrors(prevErrors => [...prevErrors, "Erreur lors de la récupération des balises Meta."]);
                }

                // Récupération des données de style
                try {
                    const styleResponse = await axios.get(`${baseUrl}/api/data-style?populate=*`);
                    const styleData = styleResponse.data.data.attributes;
                    setDataStyle(styleData);
                    injectCSSVariables(styleData); // Injecter les variables CSS dynamiquement
                } catch (err) {
                    setErrors(prevErrors => [...prevErrors, "Erreur lors de la récupération des données de style."]);
                }

                // Récupération des informations du magasin
                try {
                    const magasinResponse = await axios.get(`${baseUrl}/api/info-magasin?populate=*`);
                    setInfosMagasin(magasinResponse.data.data.attributes);
                } catch (err) {
                    setErrors(prevErrors => [...prevErrors, "Erreur lors de la récupération des informations du magasin."]);
                }

                // Récupération des informations de la page d'accueil
                try {
                    const accueilResponse = await axios.get(`${baseUrl}/api/page-accueil?populate[service_accueil][populate]=image&populate[avis_google][populate]=image_profil&populate[text_insta]=*`);
                    setPageAccueil(accueilResponse.data.data.attributes);
                } catch (err) {
                    setErrors(prevErrors => [...prevErrors, "Erreur lors de la récupération des informations de la page d'accueil."]);
                }

                // Récupération des informations de la page d'equipe
                try {
                    const equipeResponse = await axios.get(`${baseUrl}/api/page-equipe?populate[timeline]=*&populate[photo_equipe]=*&populate[membre][populate]=image`);
                    setPageEquipe(equipeResponse.data.data.attributes);
                } catch (err) {
                    setErrors(prevErrors => [...prevErrors, "Erreur lors de la récupération des informations de la page d'equipe."]);
                }

                // Récupération des informations de la page des services
                try {
                    const serviceResponse = await axios.get(`${baseUrl}/api/page-service?populate[titre_service]=*&populate[titre_service]=*&populate[descriptif_service]=*&populate[service][populate]=image`);
                    setPageService(serviceResponse.data.data.attributes);
                } catch (err) {
                    setErrors(prevErrors => [...prevErrors, "Erreur lors de la récupération des informations de la page des services."]);
                }

                // Récupération des informations de la page des marques
                try {
                    const marqueResponse = await axios.get(`${baseUrl}/api/page-marque?populate[intro]=*&populate[type_marque][populate][image]=*&populate[type_marque][populate][gallerie_marque][populate]=image`);
                    setPageMarque(marqueResponse.data.data.attributes);
                } catch (err) {
                    setErrors(prevErrors => [...prevErrors, "Erreur lors de la récupération des informations de la page des marques."]);
                }

            } catch (err) {
                console.error("Erreur générale lors de la récupération des données", err);
                setErrors(prevErrors => [...prevErrors, "Erreur générale lors de la récupération des données."]);
            } finally {
                setLoading(false); // Indiquer la fin du chargement
            }
        };

        fetchData();
    }, [baseUrl]);

    if (loading) {
        return <Loader />; // Afficher un loader pendant le chargement
    }

    return (
        <MagasinContext.Provider value={{ infosMagasin, baliseMeta, dataStyle, pageAccueil, pageEquipe, pageService, pageMarque }}>
            {errors.length > 0 && (
                <div className="error">
                    {errors.map((error, index) => (
                        <div key={index}>{error}</div>
                    ))}
                </div>
            )}
            {!errors.length && children}
        </MagasinContext.Provider>
    );
};