import "./style.scss";
import React from 'react';

/**
 * Composant BtnArrow
 * Ce composant affiche un bouton avec une icône de flèche et un texte. Le texte du bouton est dynamique et peut être un élément React ou une chaîne de caractères.
 * 
 * @param {React.ReactNode} info - Texte ou élément React à afficher à l'intérieur du bouton.
 */
const BtnArrow = ({info}) => {
  return (
    <button className="btnArrow style-7 mb-3 mb-lg-0 ms-lg-2">
      <span className="circle" aria-hidden="true">
        <span className="icon arrow"></span>
      </span>
      <span className="btnArrow-text">
        {React.isValidElement(info) ? info : <span>{info}</span>}
      </span>
    </button>
  )
};

export default BtnArrow;