import "./style.scss";
import React from 'react';

/**
 * Composant CardGoogle
 * Ce composant affiche une carte de revue Google avec une image de profil, un titre, une évaluation par étoiles, une date et un contenu.
 * 
 * @param {string} image - URL de l'image de profil du client.
 * @param {string} title - Nom du client ou titre de la revue.
 * @param {number} stars - Nombre d'étoiles (sur 5) attribuées dans la revue.
 * @param {string} date - Date de la revue.
 * @param {string} content - Contenu textuel de la revue.
 */
const CardGoogle = ({image, title, stars, date, content}) => {
  const totalStars = 5; // Nombre total d'étoiles
  const fullStars = Math.floor(stars); // Étoiles pleines
  const emptyStars = totalStars - fullStars; // Étoiles vides
  return (
    <div className="googlecard mb-4 shadow-sm border">
      <div className="row g-0">
        <div className="col-md-3 d-flex justify-content-center align-items-center p-3">
          <img src={image} className="googlecard-image rounded-circle" alt="Profil Client Google" />
        </div>
        <div className="col-md-9">
          <div className="googlecard-body">
            <h3 className="googlecard-title">{title}</h3>
            <div className="googlecard-stars">
              {[...Array(fullStars)].map((_, index) => (
                <i key={`full-${index}`} className="fas fa-star text-warning"></i>
              ))}
              {[...Array(emptyStars)].map((_, index) => (
                <i key={`empty-${index}`} className="far fa-star text-warning"></i>
              ))}
            </div>
            <p className="googlecard-date text-muted"><small>{date}</small></p>
            <p className="googlecard-content">{content}</p>         
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardGoogle;