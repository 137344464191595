import "./style.scss";
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { MagasinContext } from '../../services/MagasinContext';
import getImageUrl from "../../services/getImageUrl";
import Loader from "../Loader";

/**
 * Composant Footer
 * Ce composant affiche le pied de page du site avec des informations de contact, horaires, réseaux sociaux et liens utiles.
 */
const Footer = () => {

  const { infosMagasin, dataStyle } = useContext(MagasinContext);
  
  // Rend les Url des images
  const logo_pied_de_page = getImageUrl(dataStyle?.logo_pied_de_page);

  // Affichage d'un Loader pendant le chargement des informations si délai - s'assure que les données soient dispos avant affichage
  if (!infosMagasin || !dataStyle) {
    return <Loader />;
  }
  
  return (
    <footer className="footer__container d-flex flex-column flex-md-row align-items-center">
      <div className="footer__container--logo col-md-2">
        <img className="img-fluid" src={logo_pied_de_page} alt="Logo Tavernier" />
      </div>
      <div className="footer__container--text col-md-10">
        <div className="d-flex flex-wrap">
          <ul>
            <li><strong>Contact</strong></li>
            <li>
              <a href="https://goo.gl/maps/DRAdW187Ec5vAJ4F8" target="_blank" rel="noopener noreferrer">
                {infosMagasin.adresse_postale}<br/> {infosMagasin.code_postal_ville}
              </a>
            </li>
            <li><a href={`tel:${infosMagasin.telephone}`}>{infosMagasin.telephone}</a></li>
            <li>
              <a href={`mailto:${infosMagasin.mail}`}>{infosMagasin.mail}</a>
            </li>
          </ul>
          <ul>
            <li><strong>Ouverture</strong></li>
            <li>{infosMagasin.horaire_1}</li>
            <li>{infosMagasin.horaire_2}</li>
            <li>{infosMagasin.horaire_3}</li>
            <li>{infosMagasin.horaire_4}</li>
          </ul>
          <ul>
            <li><strong>Réseaux sociaux</strong></li>
            <li>
              <a href={infosMagasin.instagram} target="_blank" rel="noopener noreferrer">Instagram</a>
            </li>
            <li>
              <a href={infosMagasin.facebook} target="_blank" rel="noopener noreferrer">Facebook</a>
            </li>
          </ul>
        </div>
        <div className="small__text d-flex flex-wrap mt-3">
          <small className="small__text--link"><Link to="/mentions">Mentions légales & politique de confidentialité</Link></small>
          <small className="mx-2"><p>|</p></small>
          <small className="small__text--link"><a href="https://www.optic-performance.com" target="_blank" rel="noopener noreferrer">Réalisé par Optic Performance</a></small>
        </div>
      </div>
    </footer>
  );
};

export default Footer;