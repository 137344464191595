import "./style.scss";
import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Composant CardServiceLink
 * Ce composant affiche une carte de service avec une image de fond, un titre, et un lien pour en savoir plus.
 * 
 * @param {string} image - URL de l'image de fond de la carte.
 * @param {string} title - Titre du service affiché sur la carte.
 */
const CardServiceLink = ({image, title}) => {
  return (
    <div className="card text-center text-white p-0">
      <img src={image} className="CardServiceLink card-img" alt="Service" />
      <div className="CardServiceLink__overlay d-flex flex-column justify-content-center align-items-center">
          <h3 className="CardServiceLink__title">{title}</h3>
          <Link to="/services" className="btn btn-primary"><span>En Savoir Plus</span></Link>
      </div>
    </div>
  );
};

export default CardServiceLink;